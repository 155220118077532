import Home from "./components/Home";
import { Routes, Route } from "react-router-dom";
import SubPage from "./components/SubPage";
import { useState } from "react";
import { LangProvider } from "./langContext";
import ScrollToTop from "./components/ScrollTotop";

function App() {
  const [lang, setLang] = useState(true); // true = english

  function handleLangChange() {
    setLang((prevLang) => !prevLang);
  }

  return (
    <>
      <LangProvider value={{ lang, handleLangChange }}>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/:pageID" element={<SubPage />} />
          </Routes>
        </div>
      </LangProvider>
    </>
  );
}

export default App;
