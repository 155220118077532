import React, { useEffect } from "react";


let instancesCount = 0;

export default function HistoryImgs(props) {


    useEffect(() => {
        instancesCount+=1
        return()=> {
            instancesCount -=1
        }
    })

    return (
        <div className="imgs-wrapper">
            <img src={props.hist1} alt="hist1" />
            <img src={props.hist2} alt="hist2" />
            <img src={props.hist3} alt="hist3" />
            <img src={props.hist4} alt="hist4" />
        </div>
    )
}