import React, { useContext, useEffect, useRef, useState } from "react";
import LangContext from "../langContext";
import { useParams } from "react-router-dom";
import { DatePage } from "./DatePage";
import Footer from "./Footer";
import Navbar from "./Navbar";
import Toggler from "./Toggler";
const SubPage = () => {
  let params = useParams();
  const pageID = params.pageID;
  let data = require(`../${pageID}.json`);
  const { lang, handleLangChange } = useContext(LangContext);

  if (!lang && pageID === "ken") {
    data = require("../kenjp.json");
  }
  const [showNav, setShowNav] = useState(false);
  const useSectionOnScreen = (options) => {
    const sectionRef = useRef(null);

    const sectionInView = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        setShowNav(false); // when section not in view
      } else {
        setShowNav(true);
      }
    };

    useEffect(() => {
      const observer = new IntersectionObserver(sectionInView, options);

      if (sectionRef.current) observer.observe(sectionRef.current);

      return () => {
        if (sectionRef.current) observer.unobserve(sectionRef.current);
      };
    }, [sectionRef]);

    return [sectionRef];
  };
  
  // let pageInfo = data.find((datum) => datum.pageID === params.pageID);
  return (
    <section>
      <Toggler
        render={({ on, toggle }) => {
          return <Navbar showNav={showNav} on={on} toggle={toggle} />;
        }}
      />
      {data.map((pageInfo, i) => (
        <>
          <div
            key={i}
            className={`subpage__image img--${pageInfo.pageID} language__${pageInfo.language}`}
            id={pageInfo.pageID}
          />
          <div className="subpage__container ">
            <div className="two-column ">
              <div className="column lol">
                {pageInfo.pageID === "ken2" ? (
                  !lang ? (
                    <div
                      className={`column__heading heading--${pageInfo.pageID} language__${pageInfo.language}`}
                    >
                      '00<span className="zerozerotimeline">年代</span>{" "}
                      タイムライン
                    </div>
                  ) : (
                    <div
                      className={`column__heading heading--${pageInfo.pageID} language__${pageInfo.language}`}
                      id={pageInfo.pageID}
                    >
                      '00<span className="zerozerotimeline">s</span> timeline
                    </div>
                  )
                ) : (
                  <div
                    className={`column__heading heading--${pageInfo.pageID} language__${pageInfo.language}`}
                    id={pageInfo.pageID}
                  >
                    {pageInfo.title}
                  </div>
                )}

                {pageInfo?.heading?.map((value, i) => {
                  return (
                    i % 2 === 0 && (
                      <>
                        <h1 className="column__title" key={i}>
                          {value}
                        </h1>
                        {pageInfo.dateColumns === false ? (
                          pageInfo.paragraphs[i].split("&&&").map((para, i) => (
                            <p key={i} className="column__text">
                              {para}
                            </p>
                          ))
                        ) : (
                          <DatePage infos={pageInfo.paragraphs[i]} />
                        )}
                      </>
                    )
                  );
                })}
              </div>
              <div className="column">
                <div
                  className={`column__heading column__heading--hidden heading--${pageInfo.pageID}`}
                >
                  .
                </div>
                {pageInfo?.heading?.map((value, i) => {
                  return (
                    i % 2 === 1 && (
                      <>
                        <h1 className="column__title" key={i}>
                          {value}
                        </h1>
                        {pageInfo.dateColumns === false ? (
                          pageInfo.paragraphs[i].split("&&&").map((para, i) => (
                            <p key={i} className="column__text">
                              {para}
                            </p>
                          ))
                        ) : (
                          <DatePage
                            key={i - 400}
                            infos={pageInfo.paragraphs[i]}
                          />
                        )}
                      </>
                    )
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ))}
      <Navbar />

      <Footer />
    </section>
  );
};

export default SubPage;
