import React from "react";
import { Link, useNavigate } from "react-router-dom";
import HistoryImgs from "./HistoryImgs";
import Button from "./Button";

import hist1 from '../images/history1.jpg'
import hist2 from '../images/hist2.jpg'
import hist3 from '../images/hist3.jpg'
import hist4 from '../images/hist4.jpg'
import hist5 from '../images/hist5.JPG'
import hist6 from '../images/hist6.jpg'
import hist7 from '../images/hist7.jpg'
import hist8 from '../images/hist8.jpg'


export default function Container(props) {
  const link = props.title === "about" ? "about" : "ken";
  const navigate = useNavigate();


  return (
    <div className="container">
      <div
        className={`container-wrapper ${
          props.invert ? "invert-container" : ""
        }`}
      >
        <div className="text-wrapper">
          <h1 className="container-title">{props.title} </h1>
          <p className="container-text">
            {props.text}{" "}
            <a href={`/${link}`}>
              <Button />
            </a>
          </p>
        </div>
        <div className="container-children">{props.children}</div>
        {
          props.invert ? 
          <HistoryImgs hist1={hist1} hist2={hist2} hist3={hist3} hist4={hist4}/> 
          :
          <HistoryImgs hist1={hist5} hist2={hist6} hist3={hist7} hist4={hist8}/>
        }
        
      </div>
    </div>
  );
}
