import React, { useEffect, useState, useRef } from "react";
import Container from "./Container";
import Navbar from "./Navbar";
import Toggler from "./Toggler";
import Footer from "./Footer";
import map from "../images/map.jpg";
import kabab from '../images/img2.jpg'
import Background2 from "../images/banner2.jpg";
import Background3 from "../images/banner3.jpg";
import Background4 from "../images/banner4.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { db, colRef } from "../firebase";
export default function Home() {
  const [times, setTimes] = useState([]);
  useEffect(() => {
    getDocs(colRef).then((snapshot) => {
      let timesref = [];
      snapshot.docs.forEach((doc) => {
        timesref.push({ ...doc.data(), id: doc.id });
      });
      setTimes(timesref);
    });
  }, []);
  const [showNav, setShowNav] = useState(false);

  const useSectionOnScreen = (options) => {
    const sectionRef = useRef(null);

    const sectionInView = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        setShowNav(false); // when section not in view
      } else {
        setShowNav(true);
      }
    };

    useEffect(() => {
      const observer = new IntersectionObserver(sectionInView, options);

      if (sectionRef.current) observer.observe(sectionRef.current);

      return () => {
        if (sectionRef.current) observer.unobserve(sectionRef.current);
      };
    }, [sectionRef]);

    return [sectionRef];
  };

  const aboutText =
    'Yakitori consists of chicken, vegetables and seafood on bamboo skewers with salt and yakitori sauce, and requires very delicate control of charcoal heat and seasoning. It is served and eaten on the skewer so as to enjoy the aroma and flavour. The preparation of yakitori looks simple but customer satisfaction depends heavily on the charcoal. In Japan, Yakitori is one of the most popular foods which is enjoyed at least as much as "Sushi"or "Tempura".';

  const historyText =
    'In 1992, when the Asian population in Aotearoa was a mere 3%, only a handful of Japanese restaurants existed. I recall visiting one at the time, and to my disbelief, I discovered that many of the Japanese dishes were not authentic at all. Back then, there was close to no Japanese produce in the country, and yet I was determined to create an authentic Japanese dining experience. After some research about New Zealand produce, I decided that Yakitori was the answer, and hence the birth of Ken Yakitori.';

  const bookingText = `We are currently NOT taking same day reservations so please walk in on the day directly to our shop.

  We are also NOT taking bookings via the phone. 
  
  Please send us an email at least 24 hours prior to your booking. We will send you a response to confirm. Sorry for the inconvenience.`;

  const noticeText = `
    NOTICE:
    We hold "SUPERVISED License" - meaning anyone under 18 years old has to be supervised by their own parent or guardian at all times. Please bring your photo ID if you look under 25.
    All online-reservations must be received at least 24 hours previously.
    All online reservations that require more than 5 people, must be received at least 72 hours previously.
    We will confirm your booking via email or telephone.
    Please write your name, Mobile number, e-mail address, number of people, Date and Time.`;

  const bannerImages = [Background2, Background3, Background4];
  const [bannerImgCurrent, setBannerImgCurrent] = React.useState(0);

  useEffect(() => {
    const changeBackground = setTimeout(() => {
      setBannerImgCurrent((prevImg) => {
        if (prevImg + 1 === bannerImages.length) {
          return 0;
        } else {
          return prevImg + 1;
        }
      });
    }, 3000);

    return () => {
      clearTimeout(changeBackground);
    };
  });

  const styles = {
    backgroundColor: "#616161",
    backgroundImage: `url(${bannerImages[bannerImgCurrent]})`,
  };

  const location = {
    address: "55 Anzac Avenue, Auckland City",
    lat: -36.846992,
    lng: 174.77189,
  };

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  };

  const [homeRef] = useSectionOnScreen(options);

  return (
    <div className="home">
      <Toggler
        render={({ on, toggle }) => {
          return <Navbar showNav={showNav} on={on} toggle={toggle} />;
        }}
      />

      <div ref={homeRef} id="home" className="banner" style={styles}>
        <div className="title-container">
          <h1 className="title">Ken Yakitori Bar</h1>
          <h3 className="sub-title">
            ANZAC <p className="homepage__date">since 1997 </p>
          </h3>
        </div>
      </div>

      <div id="about">
        <Container invert={false} title="about" text={aboutText} />
      </div>

      <div className="images container" > 
        <img src={kabab} className='kebab' alt="yakitori" />
      </div>
      <div id="history">
        <Container invert={true} title="history of ken" text={historyText} />
      </div>

      <div className="images1 container">
        <div className="hours">
          <h1 className="container-title">{times[0]?.title}</h1>
          <p className="hours-text">{times[0]?.times} </p>
          <p className="hours-text">{times[0]?.subtext}</p>
        </div>
      </div>

      <div className="container" id="booking">
        <div className="container-wrapper">
          <div className="text-wrapper">
            <h1 className="container-title">Booking </h1>
            <p className="container-text">{bookingText}</p>
            <p className="container-text">{noticeText}</p>
          </div>
          <div className="find-us">
            <div className="contact-links">
              <a className="contact--link" href="mailto:ken-anzac@xtra.co.nz">
                <FontAwesomeIcon icon={faEnvelope} /> &nbsp;
                ken-anzac@xtra.co.nz{" "}
              </a>
              <a href="tel:093796500" className="contact--link">
                <FontAwesomeIcon icon={faPhoneAlt} />
                &nbsp; 09-379-6500{" "}
              </a>
            </div>

            <div>
              <img className="google-map" src={map} alt="map" />
            </div>

            <h2>Ken Yakitori ANZAC</h2>
            <p>55 Anzac Avenue, Auckland City</p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
