import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import logo from "../images/logo.png";
import Toggler from "./Toggler";
import LangContext from "../langContext";
import { useNavigate, useParams } from "react-router-dom";

export default function Navbar(props) {
  let navigate = useNavigate();
  const { lang, handleLangChange } = useContext(LangContext);
  let params = useParams();
  const pageID = params.pageID;
  let data =
    pageID === undefined
      ? require(`../home.json`)
      : require(`../${pageID}.json`);
  if (!lang && pageID === "ken") {
    data = require("../kenjp.json");
  }

  function onClick() {
    handleLangChange();
    props.toggle();
  }
  return (
    <div>
      {!props.on && (
        <FontAwesomeIcon
          icon={faBars}
          onClick={props.toggle}
          className="nav-btn"
        />
      )}

      {/* close */}
      {props.on && (
        <FontAwesomeIcon
          icon={faTimes}
          onClick={props.toggle}
          className="nav-cross"
        />
      )}

      <div className={`nav-wrapper ${props.on ? "open-nav" : ""}`}>
        <div
          className={`${props.showNav ? "nav-visible" : ""} nav-wrapper ${
            props.on ? "open-nav" : ""
          } `}
        >
          <nav className="nav">
            <img
              src={logo}
              className="logo"
              alt="logo"
              onClick={() => navigate(`/`)}
            />

            {data.map((datum, i) => (
              <a key={i} href={`#${datum.pageID}`} onClick={props.toggle}>
                {datum.title.toUpperCase()}
              </a>
            ))}
            {params.pageID === "ken" && (
              <p onClick={onClick}>{lang ? "ENG" : "日本語"} </p>
            )}

            <a
              href="https://www.facebook.com/KenYakitoriAnzacAve"
              className="fb-link"
              target="_blank"
            >
              <FontAwesomeIcon className="fb-icon" icon={faFacebookSquare} />
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
}
