import React from 'react'


// this is a render prop we could use to control all toggles 
export default function Toggler(props) {

    //default state is false 
    const [on, setOn] = React.useState(false);

    const toggle = () => {
        setOn(prevOn => !prevOn);
    }

    return(
        <div> 
            {props.render({on, toggle})}
        </div>
    )
}