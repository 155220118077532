import React from "react";

export const DatePage = ({ infos }) => {
  const information = infos.split("---");
  return (
    <div className="">
      {information.length > 1 ? (
        infos.split("---").map((_, i) => {
          return (
            i % 2 === 0 && (
              <div className="date--page" key={i}>
                <div className="date--page-title">{information[i]}</div>
                <div className="date--page-child">{information[i + 1]}</div>
              </div>
            )
          );
        })
      ) : (
        <div>{information[0]}</div>
      )}
    </div>
  );
};
