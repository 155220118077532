import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDA0DqfqWm1H6QyT62NSpUmdw5LNVI2BUk",
  authDomain: "kenyakitori-a754a.firebaseapp.com",
  projectId: "kenyakitori-a754a",
  storageBucket: "kenyakitori-a754a.appspot.com",
  messagingSenderId: "552940513193",
  appId: "1:552940513193:web:03491bedb6cb8c867646b8",
  measurementId: "G-D1ZS38S8S1",
};

initializeApp(firebaseConfig);
const db = getFirestore();
const colRef = collection(db, "times");

const itemGetter = () => {
  getDocs(colRef).then((snapshot) => {
    let times = [];
    snapshot.docs.forEach((doc) => {
      times.push({ ...doc.data(), id: doc.id });
    });
  });
};

export { db, colRef, itemGetter };
